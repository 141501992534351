.loaderWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 12px;
}

.header {
    display: flex;
    flex-direction: column;
}

.subtitle {
    margin-top: 4px;
}

.modal {
    display: flex;
    width: 400px;
    max-width: calc(100% - 36px);

    & > div[role="document"] {
        flex-basis: 100%;
    }
}

.footer {
    display: flex;

    @media (min-width: 768px) {
        & > .button {
            flex: 1 1;
        }

        .button:not(:last-of-type) {
            margin-right: 8px;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;

        .button:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }
}
