:root {
    --background: rgba(34, 34, 34, 0.12);
    --background-disabled: rgba(34, 34, 34, 0.12);
    --background-selected: #cc290a;
    --background-selected-hover: #9c1f08;
    --border: rgba(34, 34, 34, 0.24);
    --border-hover: rgba(34, 34, 34, 0.64);
    --dot: #ffffff;
    --dot-disabled: rgba(34, 34, 34, 0.4);
}

.label {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.radio {
    appearance: none;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    outline: none;
    position: relative;
    width: 20px;
}

.disabledLabel,
.disabled {
    cursor: default;
}

.description {
    display: flex;
    align-items: center;
    width: 100%;
}

.radio + .description {
    margin-left: 12px;
}

.radio,
.radio:after {
    transition: background-color ease-in-out 0.25ms;
}

.default:not(.selected),
.disabled:not(.selected) {
    border-color: var(--border);
}

.default:hover {
    border-color: var(--border-hover);
}

.default.selected {
    border: none;
    background-color: var(--background-selected);
}

.default.selected:hover {
    background-color: var(--background-selected-hover);
}

.default.selected:after {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--dot);
}

.disabled {
    background-color: var(--background-disabled);
}

.disabled.selected {
    background-color: var(--background-disabled);
    border: none;
}

.disabled.selected:after {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--dot-disabled);
}
