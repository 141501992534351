:root {
    --placeholder-color: rgba(34, 34, 34, 0.64);
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
}

.placeholder {
    position: absolute;
    left: 0;
    color: var(--placeholder-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: 0.2s ease all;
    pointer-events: none;

    &Default {
        top: 6px;
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 42px;
    }

    &Focused {
        top: 0;
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: 24px;
    }
}

.errorMessage {
    color: #c22a17;
    padding: 0 2px;
    margin-top: 4px;
}
