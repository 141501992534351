.button {
    composes: button from "Button.module.css";

    color: #18181b;
    background: #c2fd60;
}

.button:not(.disabled):hover,
.button:not(.disabled):active,
.button:not(.disabled):focus {
    background: #b8f05b;
}

.round {
    composes: round from "Button.module.css";
}

.disabled {
    composes: disabled from "Button.module.css";

    color: #a0a0ab;
    background: #ddffa6;
}
