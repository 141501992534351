.wrapper {
    display: flex;
    position: relative;
    text-align: left;
}

.arrowBlock {
    position: fixed;
    color: #fff;
    opacity: 0;
    pointer-events: none;

    & > svg {
        display: block;
    }
}

.tooltipContent {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #fff;
    border-radius: 12px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12));
    padding: 24px;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
    width: 390px;
    z-index: -1;
}

.shown {
    pointer-events: auto;
    opacity: 1;
    z-index: 1001;
}

.redTooltipContent {
    padding: 20px;
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.header {
    margin: 0;
}

.title {
    margin-top: 44px;
}
