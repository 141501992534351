.button {
    composes: button from "Button.module.css";

    color: #18181b;
    background: #f6f6f6;
}

.button:not(.disabled):hover,
.button:not(.disabled):active,
.button:not(.disabled):focus {
    background: #eef2f6;
}

.round {
    composes: round from "Button.module.css";
}

.disabled {
    composes: disabled from "Button.module.css";

    color: #a0a0ab;
    background: #f8f9fb;
}
