.modal {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 4px;
}

.subtitle {
    margin-bottom: 24px;

    &:not(:first-child) {
        margin-top: 0;
    }
}

.buttonWrap {
    display: flex;
    width: 100%;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}

.button {
    @media (min-width: 768px) {
        flex: 1 1;

        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }

    @media (max-width: 767px) {
        &:not(:last-of-type) {
            margin-top: 8px;
        }
    }
}
