:root {
    --margin: 24px;
}

.container {
    background: #eef2f6;
}

.wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 6px 12px;
    margin: 0 auto;
    max-width: 1280px;

    @media (min-width: 768px) {
        padding: 6px 32px;
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 356px;
}

.flexible {
    flex: 1;
    width: 100%;
    max-width: fit-content;
}

.element {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    min-width: 33px;
    padding: 4px;
    color: #18181b;
    background: #ffffff;
    justify-content: center;
    overflow: hidden;
    height: 23px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid transparent;
}

.highlight {
    border: 1px solid #eef2f6;
}

.disabled {
    background: transparent;
    color: #a0a0ab;
    cursor: default;
}

.main {
    justify-content: space-between;
    padding-right: 6px;
}

.leading {
    display: flex;
    margin-right: 4px;
}

.trailing {
    flex-shrink: 0;
}

.static {
    flex-shrink: 0;
}

.icon {
    min-width: 16px;
}

.icon + .text {
    margin-left: 4px;
}

.text {
    font-size: inherit;
    line-height: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dot {
    margin: 0 4px;
}

.link {
    font-size: inherit;
    line-height: inherit;
    color: #547dff;
}

.tooltip {
    min-width: 390px;
}

.tooltipWrap {
    display: flex;
}

.tooltipText {
    hyphens: auto;

    @media (max-width: 767px) {
        display: flex;
        margin-top: 44px;
    }
}

.tooltipButton {
    flex: 1 1 50%;
    white-space: nowrap;

    @media (max-width: 767px) {
        flex: none;
        border-radius: 28px;
        height: 56px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;

        &:not(:first-of-type) {
            margin-bottom: 12px;
        }
    }
}

.modal {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        max-height: calc(100% - var(--margin) * 2);
        min-height: 372px;
        flex-direction: row;
    }

    & > div[role="document"] {
        flex: 1 1;

        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
}

.form > div[role="document"] {
    overflow: hidden;

    & > div:nth-child(2) {
        height: 100%;
    }
}

.footer {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;

    @media (min-width: 768px) {
        & > button:not(:first-of-type) {
            margin-left: 6px;
        }
    }

    @media (max-width: 767px) {
        margin-top: 12px;
        flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
        margin-top: 12px;
        flex-direction: column-reverse;
    }
}

/* red & red-wide */
.redHeaderContainer {
    background: none;
}

.redHeaderWrapper {
    margin-top: -15px;
    padding: 0;
}

.redHeaderElement {
    padding: 0;
    height: 30px;
    font-size: 11px;
    background: transparent;
    font-weight: 500;
    color: #18181b;
    border: none;
}

.redHeaderDisabled {
    background: transparent;
    color: #fff;
}

.redHeaderIcon + .text {
    margin-left: 0;
}

.redHeaderTooltipDelivery {
    left: -50px;
}

.redHeaderTooltipWrap {
    display: none;
}

.redHeaderGeoTooltipWrap {
    height: 32px;
    border: none;
    border-radius: 8px;
    align-items: center;
}

.redHeaderBodyFixed {
    overflow: hidden;
}

@media screen and (--snow-tablet) {
    .redHeaderWrapper {
        flex-direction: row-reverse;
        margin: 0 auto;
        padding: 0;
    }

    .redHeaderIcon + .text {
        margin-left: 4px;
    }

    .redHeaderTooltipWrap {
        display: block;
        align-items: center;
    }

    .redHeaderElement {
        background: #ffffff;
        height: 32px;
        border: 1px solid #cdd5df;
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;

        color: #18181b;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;

        &:hover {
            background: #eef2f6;
        }
    }

    .redHeaderDisabled {
        color: #18181b;
        background: #eef2f6;
        border-color: #eef2f6;

        &:hover {
            background: #eef2f6;
        }
    }
}

/* choice */
.choiceContainer {
    background: none;
}

.choiceHeaderWrapper {
    display: inline-flex;
    flex-direction: row;
    gap: 4px;
    padding: inherit;
}

.choiceHeaderElement {
    padding: 8px;
    height: 28px;
    font-family: Inter, "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.005em;
    border: none;
    background: none;
    border-radius: 6px;
    color: #898993;

    &:active,
    &:hover,
    &:focus {
        background-color: #f2f3f5;
    }
}
