:root {
    --border: rgba(34, 34, 34, 0.24);
    --bg: #fff;
    --color: #222;

    --hover-border: rgba(34, 34, 34, 0.64);

    --disabled-color: rgba(34, 34, 34, 0.4);
    --disabled-bg: rgba(34, 34, 34, 0.12);
}

.root {
    position: relative;
    margin-bottom: 8px;
}

.searchInput {
    background: none;
    position: absolute;
    top: 44px;
    outline: none;
    border: none;
    padding: 12px;
    width: 100%;
    height: 34px;
    z-index: 1;
}

.search {
    margin-bottom: 12px;
}

.dropdown {
    max-height: 220px;
}

.errorMessage {
    color: #c22a17;
    padding: 0 2px;
    margin-top: 4px;
}

.control {
    position: relative;
    width: 100%;
    cursor: pointer;
    border: 2px solid var(--border);
    background-color: var(--bg);
    transition: all 0.3s ease-out;
    display: flex;
    flex-grow: 2;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 20px;
    border-radius: 28px;

    &:focus,
    &:active {
        border-color: var(--color);
    }

    &:hover {
        border-color: var(--hover-border);
    }

    &.loading {
        justify-content: center;
        cursor: default;
    }

    &.disabled {
        background-color: var(--disabled-bg);
        border-color: var(--border);
        cursor: default;

        &:hover {
            border-color: var(--border);
        }
    }
}

.inputField {
    display: flex;
    align-items: center;
    background: none;
    width: 100%;
    cursor: pointer;
    appearance: none;
    overflow: hidden;
    box-sizing: border-box;
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;

    &Pointer {
        cursor: pointer;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover,
    &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
    }
}

.select {
    display: flex;
    flex-direction: column;
}

.icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}

.dropdownMenu {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    background-color: var(--bg);
    padding: 12px;
    opacity: 0;
    list-style: none;
    outline: none;
    margin-top: -1px;
    z-index: 1;
    border-radius: 24px;
    top: 64px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    transition: opacity 300ms ease-in-out, max-height 300ms ease;

    &::-webkit-scrollbar {
        display: none;
    }

    &Open {
        opacity: 1;
        border: 2px solid var(--color);
    }
}

.list {
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.item {
    overflow: hidden;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    cursor: pointer;

    &:hover {
        background: rgba(34, 34, 34, 0.12);
        border-radius: 20px;
    }

    &Selected {
        font-weight: 600;
    }
}

.chevron {
    width: 28px;
    height: 28px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 3px;
    width: 22px;
    height: 22px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
