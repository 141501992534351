.ShipToHeaderItem_AddressForm__container__1my76 {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.ShipToHeaderItem_AddressForm__title__1my76 {
    margin-bottom: 4px;
}

.ShipToHeaderItem_AddressForm__subtitle__1my76 {
    margin-bottom: 16px;
}

.ShipToHeaderItem_AddressForm__subtitle__1my76:not(:first-child) {
        margin-top: 0;
    }

.ShipToHeaderItem_AddressForm__form__1my76 {
    flex: 1 1;
    min-height: 0;
    overflow: scroll;
}

.ShipToHeaderItem_AddressForm__buttonWrap__1my76 {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 12px;
}

.ShipToHeaderItem_AddressForm__select__1my76:not(:last-of-type) {
        margin-bottom: 8px;
    }

.ShipToHeaderItem_AddressForm__sectionTitle__1my76 {
    margin-top: 20px;
    margin-bottom: 8px;
}

.ShipToHeaderItem_AddressForm__row__1my76 {
    display: flex;
}

@media (max-width: 767px) {

.ShipToHeaderItem_AddressForm__row__1my76 {
        flex-direction: column
}
    }

@media (min-width: 768px) {

.ShipToHeaderItem_AddressForm__row__1my76 > .ShipToHeaderItem_AddressForm__input__1my76 + .ShipToHeaderItem_AddressForm__input__1my76 {
            margin-left: 8px
    }
        }

.ShipToHeaderItem_AddressForm__input__1my76 {
    flex: 1 1;
}

.ShipToHeaderItem_AddressForm__input__1my76 input:-webkit-autofill,
    .ShipToHeaderItem_AddressForm__input__1my76 input:-webkit-autofill:hover,
    .ShipToHeaderItem_AddressForm__input__1my76 input:-webkit-autofill:focus,
    .ShipToHeaderItem_AddressForm__input__1my76 input:-webkit-autofill:active {
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
    }

.ShipToHeaderItem_AddressForm__input__1my76:-webkit-autofill:active,
    .ShipToHeaderItem_AddressForm__input__1my76:-webkit-autofill:focus,
    .ShipToHeaderItem_AddressForm__input__1my76:-webkit-autofill:hover,
    .ShipToHeaderItem_AddressForm__input__1my76:-webkit-autofill {
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
    }

.ShipToHeaderItem_AddressForm__phone__1my76 {
    height: 58px;
}

.ShipToHeaderItem_AddressForm__policy__1my76 {
    text-align: center;
    color: rgba(34, 34, 34, 0.64);
    margin-top: 8px;
    font-size: 11px;
    line-height: 12px;
}

.ShipToHeaderItem_AddressForm__nonJvPolicy__1my76 {
    margin-bottom: 8px;
    font-size: 11px;
    line-height: 12px;
}

.ShipToHeaderItem_Select__root__i262h {
    position: relative;
    margin-bottom: 8px;
}

.ShipToHeaderItem_Select__searchInput__i262h {
    background: none;
    position: absolute;
    top: 44px;
    outline: none;
    border: none;
    padding: 12px;
    width: 100%;
    height: 34px;
    z-index: 1;
}

.ShipToHeaderItem_Select__search__i262h {
    margin-bottom: 12px;
}

.ShipToHeaderItem_Select__dropdown__i262h {
    max-height: 220px;
}

.ShipToHeaderItem_Select__errorMessage__i262h {
    color: #c22a17;
    padding: 0 2px;
    margin-top: 4px;
}

.ShipToHeaderItem_Select__control__i262h {
    position: relative;
    width: 100%;
    cursor: pointer;
    border: 2px solid rgba(34, 34, 34, 0.24);
    background-color: #fff;
    transition: all 0.3s ease-out;
    display: flex;
    flex-grow: 2;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 20px;
    border-radius: 28px;
}

.ShipToHeaderItem_Select__control__i262h:focus,
    .ShipToHeaderItem_Select__control__i262h:active {
        border-color: #222;
    }

.ShipToHeaderItem_Select__control__i262h:hover {
        border-color: rgba(34, 34, 34, 0.64);
    }

.ShipToHeaderItem_Select__control__i262h.ShipToHeaderItem_Select__loading__i262h {
        justify-content: center;
        cursor: default;
    }

.ShipToHeaderItem_Select__control__i262h.ShipToHeaderItem_Select__disabled__i262h {
        background-color: rgba(34, 34, 34, 0.12);
        border-color: rgba(34, 34, 34, 0.24);
        cursor: default;
    }

.ShipToHeaderItem_Select__control__i262h.ShipToHeaderItem_Select__disabled__i262h:hover {
            border-color: rgba(34, 34, 34, 0.24);
        }

.ShipToHeaderItem_Select__inputField__i262h {
    display: flex;
    align-items: center;
    background: none;
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    overflow: hidden;
    box-sizing: border-box;
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.ShipToHeaderItem_Select__inputFieldPointer__i262h {
        cursor: pointer;
    }

.ShipToHeaderItem_Select__inputField__i262h input:-webkit-autofill,
    .ShipToHeaderItem_Select__inputField__i262h input:-webkit-autofill:hover,
    .ShipToHeaderItem_Select__inputField__i262h input:-webkit-autofill:focus,
    .ShipToHeaderItem_Select__inputField__i262h input:-webkit-autofill:active {
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
    }

.ShipToHeaderItem_Select__inputField__i262h:-webkit-autofill:active,
    .ShipToHeaderItem_Select__inputField__i262h:-webkit-autofill:focus,
    .ShipToHeaderItem_Select__inputField__i262h:-webkit-autofill:hover,
    .ShipToHeaderItem_Select__inputField__i262h:-webkit-autofill {
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
    }

.ShipToHeaderItem_Select__select__i262h {
    display: flex;
    flex-direction: column;
}

.ShipToHeaderItem_Select__icon__i262h {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}

.ShipToHeaderItem_Select__dropdownMenu__i262h {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    background-color: #fff;
    padding: 12px;
    opacity: 0;
    list-style: none;
    outline: none;
    margin-top: -1px;
    z-index: 1;
    border-radius: 24px;
    top: 64px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    transition: opacity 300ms ease-in-out, max-height 300ms ease;
}

.ShipToHeaderItem_Select__dropdownMenu__i262h::-webkit-scrollbar {
        display: none;
    }

.ShipToHeaderItem_Select__dropdownMenuOpen__i262h {
        opacity: 1;
        border: 2px solid #222;
    }

.ShipToHeaderItem_Select__list__i262h {
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}

/* Firefox */

.ShipToHeaderItem_Select__list__i262h::-webkit-scrollbar {
        display: none;
    }

.ShipToHeaderItem_Select__item__i262h {
    overflow: hidden;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    cursor: pointer;
}

.ShipToHeaderItem_Select__item__i262h:hover {
        background: rgba(34, 34, 34, 0.12);
        border-radius: 20px;
    }

.ShipToHeaderItem_Select__itemSelected__i262h {
        font-weight: 600;
    }

.ShipToHeaderItem_Select__chevron__i262h {
    width: 28px;
    height: 28px;
}

.ShipToHeaderItem_Select__loader__i262h {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 3px;
    width: 22px;
    height: 22px;
}

@keyframes ShipToHeaderItem_Select__spin__i262h {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ShipToHeaderItem_TextInput__wrapper__puxag {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
}

.ShipToHeaderItem_TextInput__placeholder__puxag {
    position: absolute;
    left: 0;
    color: rgba(34, 34, 34, 0.64);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: 0.2s ease all;
    pointer-events: none;
}

.ShipToHeaderItem_TextInput__placeholderDefault__puxag {
        top: 6px;
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 42px;
    }

.ShipToHeaderItem_TextInput__placeholderFocused__puxag {
        top: 0;
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: 24px;
    }

.ShipToHeaderItem_TextInput__errorMessage__puxag {
    color: #c22a17;
    padding: 0 2px;
    margin-top: 4px;
}

.ShipToHeaderItem_ShipToHeaderItem__container__1tq58 {
    background: #eef2f6;
}

.ShipToHeaderItem_ShipToHeaderItem__wrapper__1tq58 {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    padding: 6px 12px;
    margin: 0 auto;
    max-width: 1280px;
}

@media (min-width: 768px) {

.ShipToHeaderItem_ShipToHeaderItem__wrapper__1tq58 {
        padding: 6px 32px
}
    }

.ShipToHeaderItem_ShipToHeaderItem__loader__1tq58 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 356px;
}

.ShipToHeaderItem_ShipToHeaderItem__flexible__1tq58 {
    flex: 1 1;
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.ShipToHeaderItem_ShipToHeaderItem__element__1tq58 {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    min-width: 33px;
    padding: 4px;
    color: #18181b;
    background: #ffffff;
    justify-content: center;
    overflow: hidden;
    height: 23px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid transparent;
}

.ShipToHeaderItem_ShipToHeaderItem__highlight__1tq58 {
    border: 1px solid #eef2f6;
}

.ShipToHeaderItem_ShipToHeaderItem__disabled__1tq58 {
    background: transparent;
    color: #a0a0ab;
    cursor: default;
}

.ShipToHeaderItem_ShipToHeaderItem__main__1tq58 {
    justify-content: space-between;
    padding-right: 6px;
}

.ShipToHeaderItem_ShipToHeaderItem__leading__1tq58 {
    display: flex;
    margin-right: 4px;
}

.ShipToHeaderItem_ShipToHeaderItem__trailing__1tq58 {
    flex-shrink: 0;
}

.ShipToHeaderItem_ShipToHeaderItem__static__1tq58 {
    flex-shrink: 0;
}

.ShipToHeaderItem_ShipToHeaderItem__icon__1tq58 {
    min-width: 16px;
}

.ShipToHeaderItem_ShipToHeaderItem__icon__1tq58 + .ShipToHeaderItem_ShipToHeaderItem__text__1tq58 {
    margin-left: 4px;
}

.ShipToHeaderItem_ShipToHeaderItem__text__1tq58 {
    font-size: inherit;
    line-height: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ShipToHeaderItem_ShipToHeaderItem__dot__1tq58 {
    margin: 0 4px;
}

.ShipToHeaderItem_ShipToHeaderItem__link__1tq58 {
    font-size: inherit;
    line-height: inherit;
    color: #547dff;
}

.ShipToHeaderItem_ShipToHeaderItem__tooltip__1tq58 {
    min-width: 390px;
}

.ShipToHeaderItem_ShipToHeaderItem__tooltipWrap__1tq58 {
    display: flex;
}

.ShipToHeaderItem_ShipToHeaderItem__tooltipText__1tq58 {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

@media (max-width: 767px) {

.ShipToHeaderItem_ShipToHeaderItem__tooltipText__1tq58 {
        display: flex;
        margin-top: 44px
}
    }

.ShipToHeaderItem_ShipToHeaderItem__tooltipButton__1tq58 {
    flex: 1 1 50%;
    white-space: nowrap;
}

@media (max-width: 767px) {

.ShipToHeaderItem_ShipToHeaderItem__tooltipButton__1tq58 {
        flex: none;
        border-radius: 28px;
        height: 56px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em
}

        .ShipToHeaderItem_ShipToHeaderItem__tooltipButton__1tq58:not(:first-of-type) {
            margin-bottom: 12px;
        }
    }

.ShipToHeaderItem_ShipToHeaderItem__modal__1tq58 {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {

.ShipToHeaderItem_ShipToHeaderItem__modal__1tq58 {
        max-height: calc(100% - 24px * 2);
        min-height: 372px;
        flex-direction: row
}
    }

.ShipToHeaderItem_ShipToHeaderItem__modal__1tq58 > div[role="document"] {
        flex: 1 1;

        -ms-overflow-style: none;
        scrollbar-width: none;
    }

.ShipToHeaderItem_ShipToHeaderItem__modal__1tq58 > div[role="document"] ::-webkit-scrollbar {
            display: none;
        }

.ShipToHeaderItem_ShipToHeaderItem__form__1tq58 > div[role="document"] {
    overflow: hidden;
}

.ShipToHeaderItem_ShipToHeaderItem__form__1tq58 > div[role="document"] > div:nth-child(2) {
        height: 100%;
    }

.ShipToHeaderItem_ShipToHeaderItem__footer__1tq58 {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
}

@media (min-width: 768px) {
        .ShipToHeaderItem_ShipToHeaderItem__footer__1tq58 > button:not(:first-of-type) {
            margin-left: 6px;
        }
    }

@media (max-width: 767px) {

.ShipToHeaderItem_ShipToHeaderItem__footer__1tq58 {
        margin-top: 12px;
        flex-direction: column-reverse
}
    }

@media (max-width: 767px) {

.ShipToHeaderItem_ShipToHeaderItem__footer__1tq58 {
        margin-top: 12px;
        flex-direction: column-reverse
}
    }

/* red & red-wide */

.ShipToHeaderItem_ShipToHeaderItem__redHeaderContainer__1tq58 {
    background: none;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderWrapper__1tq58 {
    margin-top: -15px;
    padding: 0;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderElement__1tq58 {
    padding: 0;
    height: 30px;
    font-size: 11px;
    background: transparent;
    font-weight: 500;
    color: #18181b;
    border: none;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderDisabled__1tq58 {
    background: transparent;
    color: #fff;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderIcon__1tq58 + .ShipToHeaderItem_ShipToHeaderItem__text__1tq58 {
    margin-left: 0;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderTooltipDelivery__1tq58 {
    left: -50px;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderTooltipWrap__1tq58 {
    display: none;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderGeoTooltipWrap__1tq58 {
    height: 32px;
    border: none;
    border-radius: 8px;
    align-items: center;
}

.ShipToHeaderItem_ShipToHeaderItem__redHeaderBodyFixed__1tq58 {
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    .ShipToHeaderItem_ShipToHeaderItem__redHeaderWrapper__1tq58 {
        flex-direction: row-reverse;
        margin: 0 auto;
        padding: 0;
    }

    .ShipToHeaderItem_ShipToHeaderItem__redHeaderIcon__1tq58 + .ShipToHeaderItem_ShipToHeaderItem__text__1tq58 {
        margin-left: 4px;
    }

    .ShipToHeaderItem_ShipToHeaderItem__redHeaderTooltipWrap__1tq58 {
        display: block;
        align-items: center;
    }

    .ShipToHeaderItem_ShipToHeaderItem__redHeaderElement__1tq58 {
        background: #ffffff;
        height: 32px;
        border: 1px solid #cdd5df;
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;

        color: #18181b;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }

        .ShipToHeaderItem_ShipToHeaderItem__redHeaderElement__1tq58:hover {
            background: #eef2f6;
        }

    .ShipToHeaderItem_ShipToHeaderItem__redHeaderDisabled__1tq58 {
        color: #18181b;
        background: #eef2f6;
        border-color: #eef2f6;
    }

        .ShipToHeaderItem_ShipToHeaderItem__redHeaderDisabled__1tq58:hover {
            background: #eef2f6;
        }
}

/* choice */

.ShipToHeaderItem_ShipToHeaderItem__choiceContainer__1tq58 {
    background: none;
}

.ShipToHeaderItem_ShipToHeaderItem__choiceHeaderWrapper__1tq58 {
    display: inline-flex;
    flex-direction: row;
    grid-gap: 4px;
    padding: inherit;
}

.ShipToHeaderItem_ShipToHeaderItem__choiceHeaderElement__1tq58 {
    padding: 8px;
    height: 28px;
    font-family: Inter, "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.005em;
    border: none;
    background: none;
    border-radius: 6px;
    color: #898993;
}

.ShipToHeaderItem_ShipToHeaderItem__choiceHeaderElement__1tq58:active,
    .ShipToHeaderItem_ShipToHeaderItem__choiceHeaderElement__1tq58:hover,
    .ShipToHeaderItem_ShipToHeaderItem__choiceHeaderElement__1tq58:focus {
        background-color: #f2f3f5;
    }

.ShipToHeaderItem_AddressList__radioWrap__1h48c {
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;
}

    .ShipToHeaderItem_AddressList__radioWrap__1h48c:not(:last-of-type) {
        margin-bottom: 24px;
    }

    .ShipToHeaderItem_AddressList__radioWrap__1h48c:last-of-type {
        margin-bottom: 16px;
    }

.ShipToHeaderItem_AddressList__title__1h48c {
    margin-bottom: 4px;
}

.ShipToHeaderItem_AddressList__subtitle__1h48c {
    margin-bottom: 24px;
}

.ShipToHeaderItem_AddressList__subtitle__1h48c:not(:first-child) {
        margin-top: 0;
    }

.ShipToHeaderItem_AddressList__icon__1h48c {
    margin-left: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ShipToHeaderItem_AddressList__buttonWrap__1h48c {
    display: flex;
    width: 100%;
}

@media (max-width: 767px) {

.ShipToHeaderItem_AddressList__buttonWrap__1h48c {
        flex-direction: column-reverse
}
    }

@media (min-width: 768px) {

.ShipToHeaderItem_AddressList__button__1h48c {
        flex: 1 1
}

        .ShipToHeaderItem_AddressList__button__1h48c:not(:last-of-type) {
            margin-right: 8px;
        }
    }

@media (max-width: 767px) {
        .ShipToHeaderItem_AddressList__button__1h48c:not(:last-of-type) {
            margin-top: 8px;
        }
    }

.ShipToHeaderItem_Radio__label__ogmlq {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.ShipToHeaderItem_Radio__radio__ogmlq {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    outline: none;
    position: relative;
    width: 20px;
}

.ShipToHeaderItem_Radio__disabledLabel__ogmlq,
.ShipToHeaderItem_Radio__disabled__ogmlq {
    cursor: default;
}

.ShipToHeaderItem_Radio__description__ogmlq {
    display: flex;
    align-items: center;
    width: 100%;
}

.ShipToHeaderItem_Radio__radio__ogmlq + .ShipToHeaderItem_Radio__description__ogmlq {
    margin-left: 12px;
}

.ShipToHeaderItem_Radio__radio__ogmlq,
.ShipToHeaderItem_Radio__radio__ogmlq:after {
    transition: background-color ease-in-out 0.25ms;
}

.ShipToHeaderItem_Radio__default__ogmlq:not(.ShipToHeaderItem_Radio__selected__ogmlq),
.ShipToHeaderItem_Radio__disabled__ogmlq:not(.ShipToHeaderItem_Radio__selected__ogmlq) {
    border-color: rgba(34, 34, 34, 0.24);
}

.ShipToHeaderItem_Radio__default__ogmlq:hover {
    border-color: rgba(34, 34, 34, 0.64);
}

.ShipToHeaderItem_Radio__default__ogmlq.ShipToHeaderItem_Radio__selected__ogmlq {
    border: none;
    background-color: #cc290a;
}

.ShipToHeaderItem_Radio__default__ogmlq.ShipToHeaderItem_Radio__selected__ogmlq:hover {
    background-color: #9c1f08;
}

.ShipToHeaderItem_Radio__default__ogmlq.ShipToHeaderItem_Radio__selected__ogmlq:after {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
}

.ShipToHeaderItem_Radio__disabled__ogmlq {
    background-color: rgba(34, 34, 34, 0.12);
}

.ShipToHeaderItem_Radio__disabled__ogmlq.ShipToHeaderItem_Radio__selected__ogmlq {
    background-color: rgba(34, 34, 34, 0.12);
    border: none;
}

.ShipToHeaderItem_Radio__disabled__ogmlq.ShipToHeaderItem_Radio__selected__ogmlq:after {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(34, 34, 34, 0.4);
}

.ShipToHeaderItem_PrivacyPolicyBlock__policy__hc8as {
    text-align: center;
    color: rgba(34, 34, 34, 0.64);
    margin-top: 8px;
    font-size: 11px;
    line-height: 12px;
}

.ShipToHeaderItem_PrivacyPolicyBlock__nonJvPolicy__hc8as {
    margin-bottom: 8px;
    font-size: 11px;
    line-height: 12px;
}

.ShipToHeaderItem_AddressOverflowReminder__loaderWrap__6267n {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 12px;
}

.ShipToHeaderItem_AddressOverflowReminder__header__6267n {
    display: flex;
    flex-direction: column;
}

.ShipToHeaderItem_AddressOverflowReminder__subtitle__6267n {
    margin-top: 4px;
}

.ShipToHeaderItem_AddressOverflowReminder__modal__6267n {
    display: flex;
    width: 400px;
    max-width: calc(100% - 36px);
}

.ShipToHeaderItem_AddressOverflowReminder__modal__6267n > div[role="document"] {
        flex-basis: 100%;
    }

.ShipToHeaderItem_AddressOverflowReminder__footer__6267n {
    display: flex;
}

@media (min-width: 768px) {
        .ShipToHeaderItem_AddressOverflowReminder__footer__6267n > .ShipToHeaderItem_AddressOverflowReminder__button__6267n {
            flex: 1 1;
        }

        .ShipToHeaderItem_AddressOverflowReminder__footer__6267n .ShipToHeaderItem_AddressOverflowReminder__button__6267n:not(:last-of-type) {
            margin-right: 8px;
        }
    }

@media (max-width: 767px) {

.ShipToHeaderItem_AddressOverflowReminder__footer__6267n {
        flex-direction: column
}

        .ShipToHeaderItem_AddressOverflowReminder__footer__6267n .ShipToHeaderItem_AddressOverflowReminder__button__6267n:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }

.ShipToHeaderItem_Backdrop__background__15mro {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    min-width: 100%;
    min-height: 100%;

    background: rgba(34, 34, 34, 0.68);
    opacity: 0.45;
}

.ShipToHeaderItem_Backdrop__content__15mro {
    position: fixed;
    z-index: 1001;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.ShipToHeaderItem_Button__button__wso54 {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 0 8px;
    height: 40px;
    font: inherit;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    position: relative;
    border: 0;
    outline: 0;
    color: #222;
    justify-content: center;
}.ShipToHeaderItem_Button__button__wso54:focus,
.ShipToHeaderItem_Button__button__wso54:active {
    outline: none;
}.ShipToHeaderItem_Button__disabled__wso54 {
    cursor: default;
}.ShipToHeaderItem_Button__round__wso54 {
    border-radius: 50%;
}

.ShipToHeaderItem_ButtonCTA__button__17o6s {

    color: #18181b;
    background: #c2fd60;
}

.ShipToHeaderItem_ButtonCTA__button__17o6s:not(.ShipToHeaderItem_ButtonCTA__disabled__17o6s):hover,
.ShipToHeaderItem_ButtonCTA__button__17o6s:not(.ShipToHeaderItem_ButtonCTA__disabled__17o6s):active,
.ShipToHeaderItem_ButtonCTA__button__17o6s:not(.ShipToHeaderItem_ButtonCTA__disabled__17o6s):focus {
    background: #b8f05b;
}

.ShipToHeaderItem_ButtonCTA__round__17o6s {
}

.ShipToHeaderItem_ButtonCTA__disabled__17o6s {

    color: #a0a0ab;
    background: #ddffa6;
}

.ShipToHeaderItem_Button__button__wso54 {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 0 8px;
    height: 40px;
    font: inherit;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    position: relative;
    border: 0;
    outline: 0;
    color: #222;
    justify-content: center;
}.ShipToHeaderItem_Button__button__wso54:focus,
.ShipToHeaderItem_Button__button__wso54:active {
    outline: none;
}.ShipToHeaderItem_Button__disabled__wso54 {
    cursor: default;
}.ShipToHeaderItem_Button__round__wso54 {
    border-radius: 50%;
}

.ShipToHeaderItem_ButtonSecondary__button__14bg3 {

    color: #18181b;
    background: #f6f6f6;
}

.ShipToHeaderItem_ButtonSecondary__button__14bg3:not(.ShipToHeaderItem_ButtonSecondary__disabled__14bg3):hover,
.ShipToHeaderItem_ButtonSecondary__button__14bg3:not(.ShipToHeaderItem_ButtonSecondary__disabled__14bg3):active,
.ShipToHeaderItem_ButtonSecondary__button__14bg3:not(.ShipToHeaderItem_ButtonSecondary__disabled__14bg3):focus {
    background: #eef2f6;
}

.ShipToHeaderItem_ButtonSecondary__round__14bg3 {
}

.ShipToHeaderItem_ButtonSecondary__disabled__14bg3 {

    color: #a0a0ab;
    background: #f8f9fb;
}

.ShipToHeaderItem_Button__button__wso54 {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 0 8px;
    height: 40px;
    font: inherit;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    position: relative;
    border: 0;
    outline: 0;
    color: #222;
    justify-content: center;
}.ShipToHeaderItem_Button__button__wso54:focus,
.ShipToHeaderItem_Button__button__wso54:active {
    outline: none;
}.ShipToHeaderItem_Button__disabled__wso54 {
    cursor: default;
}.ShipToHeaderItem_Button__round__wso54 {
    border-radius: 50%;
}

.ShipToHeaderItem_ButtonTertiary__button__cpike {

    background: transparent;
    color: #18181b;
}

.ShipToHeaderItem_ButtonTertiary__round__cpike {
}

.ShipToHeaderItem_ButtonTertiary__disabled__cpike {

    color: #a0a0ab;
}

.ShipToHeaderItem_DeleteModal__modal__bkcki {
    display: flex;
    flex-direction: column;
}

.ShipToHeaderItem_DeleteModal__title__bkcki {
    margin-bottom: 4px;
}

.ShipToHeaderItem_DeleteModal__subtitle__bkcki {
    margin-bottom: 24px;
}

.ShipToHeaderItem_DeleteModal__subtitle__bkcki:not(:first-child) {
        margin-top: 0;
    }

.ShipToHeaderItem_DeleteModal__buttonWrap__bkcki {
    display: flex;
    width: 100%;
}

@media (max-width: 767px) {

.ShipToHeaderItem_DeleteModal__buttonWrap__bkcki {
        flex-direction: column-reverse
}
    }

@media (min-width: 768px) {

.ShipToHeaderItem_DeleteModal__button__bkcki {
        flex: 1 1
}

        .ShipToHeaderItem_DeleteModal__button__bkcki:not(:last-of-type) {
            margin-right: 8px;
        }
    }

@media (max-width: 767px) {
        .ShipToHeaderItem_DeleteModal__button__bkcki:not(:last-of-type) {
            margin-top: 8px;
        }
    }

.ShipToHeaderItem_GeoTooltip__flexible__h6wam {
    flex: 1 1;
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.ShipToHeaderItem_GeoTooltip__element__h6wam {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    min-width: 33px;
    padding: 4px;
    color: #18181b;
    background: #ffffff;
    justify-content: center;
    overflow: hidden;
    height: 23px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid transparent;
}

.ShipToHeaderItem_GeoTooltip__main__h6wam {
    justify-content: space-between;
    padding-right: 6px;
}

.ShipToHeaderItem_GeoTooltip__leading__h6wam {
    display: flex;
    margin-right: 4px;
}

.ShipToHeaderItem_GeoTooltip__icon__h6wam {
    min-width: 16px;
}

.ShipToHeaderItem_GeoTooltip__icon__h6wam + .ShipToHeaderItem_GeoTooltip__text__h6wam {
    margin-left: 4px;
}

.ShipToHeaderItem_GeoTooltip__text__h6wam {
    font-size: inherit;
    line-height: inherit;
    white-space: nowrap;
    font-family: inherit;
    font-weight: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ShipToHeaderItem_GeoTooltip__tooltip__h6wam {
    min-width: 390px;
}

.ShipToHeaderItem_GeoTooltip__tooltipWrap__h6wam {
    display: flex;
}

.ShipToHeaderItem_GeoTooltip__tooltipText__h6wam {
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__tooltipText__h6wam {
        display: flex;
        margin-top: 44px
}
    }

.ShipToHeaderItem_GeoTooltip__tooltipButton__h6wam {
    flex: 1 1 50%;
    white-space: nowrap;
}

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__tooltipButton__h6wam {
        flex: none;
        border-radius: 28px;
        height: 56px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em
}

        .ShipToHeaderItem_GeoTooltip__tooltipButton__h6wam:not(:first-of-type) {
            margin-bottom: 12px;
        }
    }

.ShipToHeaderItem_GeoTooltip__footer__h6wam {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
}

@media (min-width: 768px) {
        .ShipToHeaderItem_GeoTooltip__footer__h6wam > button:not(:first-of-type) {
            margin-left: 6px;
        }
    }

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__footer__h6wam {
        margin-top: 12px;
        flex-direction: column-reverse
}
    }

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__footer__h6wam {
        margin-top: 12px;
        flex-direction: column-reverse
}
    }

.ShipToHeaderItem_GeoTooltip__redHeaderContainer__h6wam {
    background: none;
}

.ShipToHeaderItem_GeoTooltip__redHeaderElement__h6wam {
    padding: 0;
    height: 30px;
    font-size: 11px;
    background: transparent;
    font-weight: 500;
    color: #18181b;
}

.ShipToHeaderItem_GeoTooltip__redHeaderTooltipDelivery__h6wam {
    left: -50px;
}

.ShipToHeaderItem_GeoTooltip__redHeaderTooltipWrap__h6wam {
    display: none;
}

.ShipToHeaderItem_GeoTooltip__redHeaderGeoTooltipWrap__h6wam {
    height: 32px;
    border: none;
    border-radius: 8px;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .ShipToHeaderItem_GeoTooltip__redHeaderIcon__h6wam + .ShipToHeaderItem_GeoTooltip__text__h6wam {
        margin-left: 4px;
    }

    .ShipToHeaderItem_GeoTooltip__redHeaderTooltipWrap__h6wam {
        display: block;
        align-items: center;
    }

    .ShipToHeaderItem_GeoTooltip__redHeaderElement__h6wam {
        background: #ffffff;
        height: 32px;
        border: 1px solid #cdd5df;
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;

        color: #18181b;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }

        .ShipToHeaderItem_GeoTooltip__redHeaderElement__h6wam:hover {
            background: #eef2f6;
        }

    .ShipToHeaderItem_GeoTooltip__highlight__h6wam {
        border: 1px solid #eef2f6;
    }
}

.ShipToHeaderItem_GeoTooltip__mapGeoTooltip__h6wam {
    width: 328px;
}

.ShipToHeaderItem_GeoTooltip__mapGeoTitle__h6wam {
    display: flex;
    flex-direction: column;
    color: #18181b;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 0;
}

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__mapGeoTitle__h6wam {
        margin-right: 4px
}
    }

.ShipToHeaderItem_GeoTooltip__mapGeoLink__h6wam {
    display: flex;
    align-items: center;
    color: #547dff;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 12px;
    cursor: pointer;
}

.ShipToHeaderItem_GeoTooltip__pointer__h6wam {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-right: 4px;
}

.ShipToHeaderItem_GeoTooltip__mapGeoButtonFooter__h6wam {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
}

@media (min-width: 768px) {
        .ShipToHeaderItem_GeoTooltip__mapGeoButtonFooter__h6wam > .ShipToHeaderItem_GeoTooltip__mapGeoButton__h6wam:not(:first-of-type) {
            margin-left: 6px;
        }
    }

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__mapGeoButtonFooter__h6wam {
        flex-direction: column;
        margin-top: 8px
}
    }

.ShipToHeaderItem_GeoTooltip__mapGeoButton__h6wam {
    flex: 1 1;
}

@media (max-width: 767px) {

.ShipToHeaderItem_GeoTooltip__mapGeoButton__h6wam {
        flex: none;
        color: #18181b;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px
}

        .ShipToHeaderItem_GeoTooltip__mapGeoButton__h6wam:not(:first-of-type) {
            margin-top: 8px;
        }
    }

.ShipToHeaderItem_GeoTooltip__choiceHeaderIcon__h6wam {
    height: 12px;
    width: 12px;
    min-height: 12px;
    min-width: 12px;
    color: #a0a0ab;
}

.ShipToHeaderItem_GeoTooltip__choiceHeaderElement__h6wam {
    padding: 8px;
    height: 28px;
    font-family: Inter, "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.005em;
    border: none;
    background: none;
    border: 6px;
    color: #898993;
}

.ShipToHeaderItem_GeoTooltip__choiceHeaderElement__h6wam:active,
    .ShipToHeaderItem_GeoTooltip__choiceHeaderElement__h6wam:hover,
    .ShipToHeaderItem_GeoTooltip__choiceHeaderElement__h6wam:focus {
        background-color: #f2f3f5;
    }

.ShipToHeaderItem_GeoTooltip__choiceHeaderGeoTooltipWrap__h6wam {
    order: -1;
}

.ShipToHeaderItem_List__wrapper__4utit {
    display: flex;
    flex-direction: column;
    position: relative;
}

.ShipToHeaderItem_List__main__4utit {
    display: flex;
    align-items: center;
    position: relative;
}

.ShipToHeaderItem_List__input__4utit {
    position: sticky;
    top: 0;
    background: #fff;
    padding-bottom: 12px;
    z-index: 1;
}

.ShipToHeaderItem_List__element__4utit {
    display: flex;
    padding: 8px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}

.ShipToHeaderItem_List__element__4utit:hover {
        background: rgba(34, 34, 34, 0.12);
    }

.ShipToHeaderItem_List__text__4utit {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.ShipToHeaderItem_List__icon__4utit {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.ShipToHeaderItem_List__high__4utit {
    border-radius: 30px;
}

.ShipToHeaderItem_List__loader__4utit {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ShipToHeaderItem_Portal__portal__1o2hx {
    position: absolute;
    top: 0;
    bottom: 0;
}

.ShipToHeaderItem_SnowTooltip__wrapper__15k1b {
}

.ShipToHeaderItem_SnowTooltip__tooltipContent__15k1b {
    display: flex;
    flex-direction: column;
    position: fixed;
    max-height: 356px;
    width: 386px;
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    color: #18181b;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.12), 0 0 4px rgba(0, 0, 0, 0.12);
    transition: opacity 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

.ShipToHeaderItem_SnowTooltip__shown__15k1b {
    opacity: 1;
    pointer-events: unset;
    z-index: 1001;
}

.ShipToHeaderItem_SnowTooltip__title__15k1b {
    margin-bottom: 8px;
}

.ShipToHeaderItem_SnowTooltip__contentWrap__15k1b {
    background: #ffffff;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ShipToHeaderItem_SnowTooltip__contentWrap__15k1b::-webkit-scrollbar {
        display: none;
    }

.ShipToHeaderItem_PortalToastContext__portal__1xkom {
    position: relative;
}

.ShipToHeaderItem_PortalToastContext__container__1xkom {
    display: flex;
    position: fixed;
    top: 44px;
    left: 0;
    width: 100%;
    z-index: 1100;
    justify-content: center;
}

@media (min-width: 768px) {

.ShipToHeaderItem_PortalToastContext__container__1xkom {
        bottom: 16px;
        left: 16px;
        top: auto;
        transform: none;
        max-width: 378px;
        justify-content: flex-start
}
    }

.ShipToHeaderItem_List__wrapper__1abwy {
    display: flex;
    flex-direction: column;
    position: relative;
}

.ShipToHeaderItem_List__main__1abwy {
    display: flex;
    align-items: center;
    position: relative;
}

.ShipToHeaderItem_List__input__1abwy {
    position: sticky;
    top: 0;
    background: #fff;
    padding-bottom: 12px;
    z-index: 1;
}

.ShipToHeaderItem_List__element__1abwy {
    display: flex;
    padding: 12px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e6eaf0;
    background-color: #f8fafc;
}

.ShipToHeaderItem_List__element__1abwy:hover {
        border-color: #d4fa96;
    }

.ShipToHeaderItem_List__element__1abwy:not(:last-of-type) {
        margin-bottom: 8px;
    }

.ShipToHeaderItem_List__current__1abwy {
    background-color: #fff;
    border-color: #bef550;
}

.ShipToHeaderItem_List__current__1abwy:hover {
        border-color: #b8f05b;
    }

.ShipToHeaderItem_List__text__1abwy {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    color: #18181b;
}

.ShipToHeaderItem_List__subtitle__1abwy {
    margin-top: 4px;
    color: #a0a0ab;
}

.ShipToHeaderItem_RedAddressListModal__loaderWrap__2yzol {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 12px;
}

.ShipToHeaderItem_RedAddressListModal__modal__2yzol {
    flex: 1 1;
}

@media (min-width: 768px) {

.ShipToHeaderItem_RedAddressListModal__modal__2yzol {
        display: flex;
        max-height: calc(100vh - 64px * 2);
        width: 440px
}

        .ShipToHeaderItem_RedAddressListModal__modal__2yzol > div[role="document"] {
            flex-basis: 100%;
            width: 100%;
        }
    }

.ShipToHeaderItem_RedAddressListModal__footer__2yzol {
    display: flex;
    padding: 8px 0;
}

@media (min-width: 768px) {
        .ShipToHeaderItem_RedAddressListModal__footer__2yzol > .ShipToHeaderItem_RedAddressListModal__button__2yzol {
            flex: 1 1;
        }

        .ShipToHeaderItem_RedAddressListModal__footer__2yzol .ShipToHeaderItem_RedAddressListModal__button__2yzol:not(:last-of-type) {
            margin-right: 8px;
        }
    }

@media (max-width: 767px) {

.ShipToHeaderItem_RedAddressListModal__footer__2yzol {
        flex-direction: column;
        flex: 1 1
}

        .ShipToHeaderItem_RedAddressListModal__footer__2yzol > .ShipToHeaderItem_RedAddressListModal__button__2yzol {
            flex: 1 1;
        }

        .ShipToHeaderItem_RedAddressListModal__footer__2yzol .ShipToHeaderItem_RedAddressListModal__button__2yzol:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }

.ShipToHeaderItem_Tooltip__wrapper__e8wij {
    display: flex;
    position: relative;
    text-align: left;
}

.ShipToHeaderItem_Tooltip__arrowBlock__e8wij {
    position: fixed;
    color: #fff;
    opacity: 0;
    pointer-events: none;
}

.ShipToHeaderItem_Tooltip__arrowBlock__e8wij > svg {
        display: block;
    }

.ShipToHeaderItem_Tooltip__tooltipContent__e8wij {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #fff;
    border-radius: 12px;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12));
    padding: 24px;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
    width: 390px;
    z-index: -1;
}

.ShipToHeaderItem_Tooltip__shown__e8wij {
    pointer-events: auto;
    opacity: 1;
    z-index: 1001;
}

.ShipToHeaderItem_Tooltip__redTooltipContent__e8wij {
    padding: 20px;
}

.ShipToHeaderItem_Tooltip__close__e8wij {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.ShipToHeaderItem_Tooltip__header__e8wij {
    margin: 0;
}

.ShipToHeaderItem_Tooltip__title__e8wij {
    margin-top: 44px;
}

/*# sourceMappingURL=ShipToHeaderItem.css.map */