.flexible {
    flex: 1;
    width: 100%;
    max-width: fit-content;
}

.element {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    min-width: 33px;
    padding: 4px;
    color: #18181b;
    background: #ffffff;
    justify-content: center;
    overflow: hidden;
    height: 23px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid transparent;
}

.main {
    justify-content: space-between;
    padding-right: 6px;
}

.leading {
    display: flex;
    margin-right: 4px;
}

.icon {
    min-width: 16px;
}

.icon + .text {
    margin-left: 4px;
}

.text {
    font-size: inherit;
    line-height: inherit;
    white-space: nowrap;
    font-family: inherit;
    font-weight: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip {
    min-width: 390px;
}

.tooltipWrap {
    display: flex;
}

.tooltipText {
    hyphens: auto;

    @media (max-width: 767px) {
        display: flex;
        margin-top: 44px;
    }
}

.tooltipButton {
    flex: 1 1 50%;
    white-space: nowrap;

    @media (max-width: 767px) {
        flex: none;
        border-radius: 28px;
        height: 56px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;

        &:not(:first-of-type) {
            margin-bottom: 12px;
        }
    }
}

.footer {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;

    @media (min-width: 768px) {
        & > button:not(:first-of-type) {
            margin-left: 6px;
        }
    }

    @media (max-width: 767px) {
        margin-top: 12px;
        flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
        margin-top: 12px;
        flex-direction: column-reverse;
    }
}

.redHeaderContainer {
    background: none;
}

.redHeaderElement {
    padding: 0;
    height: 30px;
    font-size: 11px;
    background: transparent;
    font-weight: 500;
    color: #18181b;
}

.redHeaderTooltipDelivery {
    left: -50px;
}

.redHeaderTooltipWrap {
    display: none;
}

.redHeaderGeoTooltipWrap {
    height: 32px;
    border: none;
    border-radius: 8px;
    align-items: center;
}

@media screen and (--snow-tablet) {
    .redHeaderIcon + .text {
        margin-left: 4px;
    }

    .redHeaderTooltipWrap {
        display: block;
        align-items: center;
    }

    .redHeaderElement {
        background: #ffffff;
        height: 32px;
        border: 1px solid #cdd5df;
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;

        color: #18181b;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;

        &:hover {
            background: #eef2f6;
        }
    }

    .highlight {
        border: 1px solid #eef2f6;
    }
}

.mapGeoTooltip {
    width: 328px;
}

.mapGeoTitle {
    display: flex;
    flex-direction: column;
    color: #18181b;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 0;

    @media (max-width: 767px) {
        margin-right: 4px;
    }
}

.mapGeoLink {
    display: flex;
    align-items: center;
    color: #547dff;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 12px;
    cursor: pointer;
}

.pointer {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-right: 4px;
}

.mapGeoButtonFooter {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;

    @media (min-width: 768px) {
        & > .mapGeoButton:not(:first-of-type) {
            margin-left: 6px;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 8px;
    }
}

.mapGeoButton {
    flex: 1;

    @media (max-width: 767px) {
        flex: none;
        color: #18181b;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        &:not(:first-of-type) {
            margin-top: 8px;
        }
    }
}

.choiceHeaderIcon {
    height: 12px;
    width: 12px;
    min-height: 12px;
    min-width: 12px;
    color: #a0a0ab;
}

.choiceHeaderElement {
    padding: 8px;
    height: 28px;
    font-family: Inter, "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.005em;
    border: none;
    background: none;
    border: 6px;
    color: #898993;

    &:active,
    &:hover,
    &:focus {
        background-color: #f2f3f5;
    }
}

.choiceHeaderGeoTooltipWrap {
    order: -1;
}
