.button {
    composes: button from "Button.module.css";

    background: transparent;
    color: #18181b;
}

.round {
    composes: round from "Button.module.css";
}

.disabled {
    composes: disabled from "Button.module.css";

    color: #a0a0ab;
}
