.wrapper {
}

.tooltipContent {
    display: flex;
    flex-direction: column;
    position: fixed;
    max-height: 356px;
    width: 386px;
    background: #fff;
    border-radius: 24px;
    padding: 16px;
    color: #18181b;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.12), 0 0 4px rgba(0, 0, 0, 0.12);
    transition: opacity 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

.shown {
    opacity: 1;
    pointer-events: unset;
    z-index: 1001;
}

.title {
    margin-bottom: 8px;
}

.contentWrap {
    background: #ffffff;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
