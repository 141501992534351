.radioWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;

    &:not(:last-of-type) {
        margin-bottom: 24px;
    }

    &:last-of-type {
        margin-bottom: 16px;
    }
}

.title {
    margin-bottom: 4px;
}

.subtitle {
    margin-bottom: 24px;

    &:not(:first-child) {
        margin-top: 0;
    }
}

.icon {
    margin-left: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.buttonWrap {
    display: flex;
    width: 100%;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}

.button {
    @media (min-width: 768px) {
        flex: 1 1;

        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }

    @media (max-width: 767px) {
        &:not(:last-of-type) {
            margin-top: 8px;
        }
    }
}
