.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.main {
    display: flex;
    align-items: center;
    position: relative;
}

.input {
    position: sticky;
    top: 0;
    background: #fff;
    padding-bottom: 12px;
    z-index: 1;
}

.element {
    display: flex;
    padding: 12px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e6eaf0;
    background-color: #f8fafc;

    &:hover {
        border-color: #d4fa96;
    }

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
}

.current {
    background-color: #fff;
    border-color: #bef550;

    &:hover {
        border-color: #b8f05b;
    }
}

.text {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    color: #18181b;
}

.subtitle {
    margin-top: 4px;
    color: #a0a0ab;
}
