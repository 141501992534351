.policy {
    text-align: center;
    color: rgba(34, 34, 34, 0.64);
    margin-top: 8px;
    font-size: 11px;
    line-height: 12px;
}

.nonJvPolicy {
    margin-bottom: 8px;
    font-size: 11px;
    line-height: 12px;
}
