.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.title {
    margin-bottom: 4px;
}

.subtitle {
    margin-bottom: 16px;

    &:not(:first-child) {
        margin-top: 0;
    }
}

.form {
    flex: 1 1;
    min-height: 0;
    overflow: scroll;
}

.buttonWrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 12px;
}

.select {
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
}

.sectionTitle {
    margin-top: 20px;
    margin-bottom: 8px;
}

.row {
    display: flex;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    & > .input + .input {
        @media (min-width: 768px) {
            margin-left: 8px;
        }
    }
}

.input {
    flex: 1 1;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover,
    &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
    }
}

.phone {
    height: 58px;
}

.policy {
    text-align: center;
    color: rgba(34, 34, 34, 0.64);
    margin-top: 8px;
    font-size: 11px;
    line-height: 12px;
}

.nonJvPolicy {
    margin-bottom: 8px;
    font-size: 11px;
    line-height: 12px;
}
