.loaderWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 12px;
}

.modal {
    flex: 1;
    @media (min-width: 768px) {
        display: flex;
        max-height: calc(100vh - 64px * 2);
        width: 440px;

        & > div[role="document"] {
            flex-basis: 100%;
            width: 100%;
        }
    }
}

.footer {
    display: flex;
    padding: 8px 0;

    @media (min-width: 768px) {
        & > .button {
            flex: 1 1;
        }

        .button:not(:last-of-type) {
            margin-right: 8px;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;
        flex: 1 1;

        & > .button {
            flex: 1 1;
        }

        .button:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }
}
